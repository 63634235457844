import axios from 'axios';

const API = axios.create({ baseURL: 'https://expenseroom.herokuapp.com/' });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }

  return req;
});

export const fetchPost = (id) => API.get(`/posts/${id}`);
export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
export const fetchPostsBySearch = (searchQuery) => API.get(`/posts/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
export const createPost = (newPost) => API.post('/posts', newPost);


export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);

export const createCost = (newCost) => API.post('/createCost', newCost);
export const fetchCost = (id) => API.get(`/costs/${id}`);
export const fetchCosts = () => API.get(`/costs`);
export const deleteCost = (id) => API.delete(`/costs/${id}`);
export const updateCost = (id, updatedCost) => API.patch(`/costs/${id}`, updatedCost);

export const getRenewals = () => API.get(`/renewals`);

export const createContract = (newContract) => API.post('/contracts', newContract);
export const fetchContract = (id) => API.get(`/contracts/${id}`);
export const fetchContracts = (id) => API.get(`/contracts/${id}`);
export const deleteContract = (id) => API.delete(`/contract/${id}`);
export const deleteContracts = (id) => API.delete(`/contracts/${id}`);
export const updateContract = (id, updatedContract) => API.patch(`/contract/${id}`, updatedContract);

export const getDashChartData = () => API.get(`/dashboard`);

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);

export const googleSignup = (googleData) => API.post('/user/googleSignup', googleData);
