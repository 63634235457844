import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, CircularProgress, Container, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { getCosts, deleteCost } from '../../actions/costs'
import { deleteContracts } from '../../actions/contracts'
import { getDashChartData } from '../../actions/dashboard';
import ConfirmDialog from './ConfirmDialog/ConfirmDialog';

// import useStyles from './styles';

const Costs = ({ setCurrentId }) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  
  // const [chartData, setChartData] = useState({}) // Renewals by Expense in 2022
  const [confirmOpen, setConfirmOpen] = useState(false);
  
  const { costs, isLoading } = useSelector((costState) => costState.costs);
  const chartDataFromState = useSelector((state) => state.chartData.chartData);

  


  useEffect(() => {
    dispatch(getCosts());
    dispatch(getDashChartData());
  }, [dispatch]);


  for (var property in costs) {
    for (var refRow in chartDataFromState.labels) {
      if (chartDataFromState.labels[refRow] === costs[property].expenseName) {
        costs[property].annualizedCost = chartDataFromState.data[refRow];
      }      
    }
  }

  const editCostButton = (params) => {
    return (
      <strong>
        <Button
          variant="contained" color="primary" size="small" style={{ marginLeft: 4 }}
          onClick={() => {
            
            openCost(params)
          }}
        >
          Edit
        </Button>
      </strong>
    )
  }

  const deleteCostButton = (params) => {
    const onConfirm = () => { 
      console.log(params.id)
      dispatch(deleteContracts(params.id))
      dispatch(deleteCost(params.id))
    } 
  
    return (
      <strong>
        <Button
          variant="contained" color="secondary" size="small" style={{ marginLeft: 4 }}
          onClick={() => {
            setConfirmOpen(true)
          }}
        >
          Delete
        </Button>
        <ConfirmDialog title="Delete Cost?"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={onConfirm}
        >
          Are you sure you want to delete this cost?
        </ConfirmDialog>
      </strong>
    )
  }

  const columns = [
    { field: 'expenseName', headerName: 'Expense Name', width: 200 },
    { field: 'annualizedCost', headerName: 'Annualized Cost', width: 200, 
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `$ ${valueFormatted} `;
      },
    },
    { field: 'vendor', headerName: 'Vendor', width: 150 },
    { field: 'group', headerName: 'Group or Department', width: 200 },
    { field: 'description', headerName: 'Cost Description', width: 250 },
    { field: 'editCol', headerName: 'Edit', width: 100, renderCell: editCostButton },
    { field: 'deleteCol', headerName: 'Delete', width: 100, renderCell: deleteCostButton },
  ];

  const openCost = (params) => {
    // dispatch(getPost(post._id, history));

    history.push(`/room/${params.id}`);
  };

  return (
    isLoading ? <CircularProgress /> : (
      <Container component="main" maxWidth="xl">
        <Paper>
          <Grid container >
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid
                rows={costs ? costs : []}
                columns={columns}
                getRowId={(row) => row._id}
                density="compact"

              />
            </div>
          </Grid>
        </Paper>
      </Container>
    )
  );
};

export default Costs;
