import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Paper, Grid, Typography, Container, TextField } from '@material-ui/core';

import { useParams, useHistory } from 'react-router-dom';

import { createCost, getCost, updateCost } from '../../actions/costs';
import { createContract, getContracts, deleteContract, updateContract } from '../../actions/contracts'
import useStyles from './styles';
import Input from './Input';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';


const today = new Date();
let oneYearFromToday = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
oneYearFromToday.setDate(today.getDate() - 1)
const initialContractState = { cost_id: '', referenceD: '', contractTerm: 'Annual', contractStart: today.toISOString().substr(0, 10), contractEnd: oneYearFromToday.toISOString().substr(0, 10), costPerRenewal: '', seatCount: '', contractNotes: '', autoRenew: '', contractID: ''}
const initialState = { expenseName: '', vendor: '', group: '', description: ''};

const initialIsUpdatingContract = false;
const initialIsUpdatingCost = false;
const initialIsRenewing = {renewingStatus: false, toAssignChildID: false, renewingID: '', parentRowData: {cost_id: '', referenceD: '', contractTerm: 'Annual', contractStart: today.toISOString().substr(0, 10), contractEnd: oneYearFromToday.toISOString().substr(0, 10), costPerRenewal: '', seatCount: '', contractNotes: '', autoRenew: '', contractID: ''}};


const Createcost = (params) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [costData, setCostData] = useState(initialState);
  const [contractData, setContractData] = useState(initialContractState);
  const [isUpdatingContract, setIsUpdatingContract] = useState(initialIsUpdatingContract);
  const [isUpdatingCost, setIsUpdatingCost] = useState(initialIsUpdatingCost);
  const [isRenewing, setIsRenewing] = useState(initialIsRenewing);
  
  const { contracts } = useSelector((state) => state.contracts);
  const { id } = useParams();
  const { costs } = useSelector((costState) => costState.costs); // Read all costs in state
  const thisCost = costs.filter(itemInArray => itemInArray._id === id) // if an ID is present (if editing), isolate state 




  useEffect(() => {
    if (id) { 
    dispatch(getCost(id))  
    } else { }
  }, [dispatch])

  const cost = useSelector((state) => state.costs.cost);
  
  if (id && costs?.length === 0 && typeof cost == 'object' && costData.expenseName === ''& isUpdatingCost === false) {
    setCostData({ expenseName: cost.expenseName, vendor: cost.vendor, group: cost.group, description: cost.description});
  } else {

  }
  
  let renewedContracts = []
  useEffect(() => { // create the array of renewed contracts
      for (const [key] of Object.entries(contracts)) {
        if(contracts[key].renewalParentID) {
          renewedContracts.push(contracts[key].renewalParentID)
        }
      }
  })


  useEffect(() => {

    if (id) {
      dispatch(getContracts(id));
      try { setCostData({ expenseName: thisCost[0]['expenseName'], vendor: thisCost[0]['vendor'], group: thisCost[0]['group'], description: thisCost[0]['description']});
      //try { setCostData({ expenseName: cost.expenseName, vendor: cost.vendor, group: cost.group, description: cost.description});
      
      } catch(e) { }
    } else {
      setIsUpdatingCost({isUpdatingCost: true});
    }   

 

  }, [dispatch, isRenewing])


  if (contracts.length !== 0) {
    try { 
      for (const [key] of Object.entries(contracts)) {
        contracts[key].contractStart = contracts[key].contractStart.substring(0,10);

        if (contracts[key].autoRenew === "Yes") {
          contracts[key].contractEnd = "Ongoing"
        } else {
          contracts[key].contractEnd = contracts[key].contractEnd.substring(0,10);
        }
      }
    } catch (e) {}
  }
  
  
  const clearCost = () => {
    setCostData({ expenseName: '', vendor: '', group: '', description: ''});
    setContractData({ cost_id: '', referenceD: '', contractTerm: '', contractStart: '', contractEnd: '', costPerRenewal: '', seatCount: '', contractNotes: '', autoRenew: ''})
    // setIsRenewing({renewingStatus: false, renewingID: '', parentRowData: {}})
  };

  const clearContract = () => {
    setContractData({ cost_id: '', referenceD: '', contractTerm: 'Annual', contractStart: today.toISOString().substr(0, 10), contractEnd: oneYearFromToday.toISOString().substr(0, 10), costPerRenewal: '', seatCount: '', contractNotes: '', autoRenew: ''})
    // setIsRenewing({renewingStatus: false, renewingID: '', parentRowData: {}})
  };
  
  function addMonths(date, months, days) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    date.setDate(d + days);
    return date;
  }
  
  const handleCostSubmit = async (e) => {
    e.preventDefault();
        
    if (!id) { // new cost creation
      dispatch(createCost({ ...costData, name: user?.result?.name, googleId: user?.result?.googleId }, history));
      clearCost();
    } else { // updateding existing cost
      if (isUpdatingCost) { 
        dispatch(updateCost(id, { ...costData, name: user?.result?.name, googleId: user?.result?.googleId }));
        setIsUpdatingCost(!isUpdatingCost)
      } else {
        setIsUpdatingCost(!isUpdatingCost)
      }
    }
  };

  const handleContractSubmit = async (e) => {
    e.preventDefault();

    if (!isUpdatingContract && !isRenewing.renewingStatus) { // new contract creation
      dispatch(createContract({ ...contractData, cost_id: id, name: user?.result?.name, googleId: user?.result?.googleId }, history));
    } else if (isUpdatingContract && !isRenewing.renewingStatus) { // editing contract
      dispatch(updateContract(contractData.contractID, { ...contractData, cost_id: id, name: user?.result?.name, googleId: user?.result?.googleId }, history));
    } else if (isRenewing.renewingStatus) { // renewing a contract
      dispatch(createContract({ ...contractData, renewalParentID: isRenewing.renewingID, cost_id: id, name: user?.result?.name, googleId: user?.result?.googleId }, history));
      setIsRenewing({renewingStatus: false, toAssignChildID: true, renewingID: isRenewing.renewingID, parentRowData: isRenewing.parentRowData});
    }
    clearContract()
    setIsUpdatingContract(false)
    
  };

  const CancelEditCostButton = (params) => {
    return (
      <Button
        variant="contained" color="secondary" className={classes.submit}
        onClick={() => {
          if (id) { 
            setIsUpdatingCost(!isUpdatingCost);
            setCostData({ expenseName: cost.expenseName, vendor: cost.vendor, group: cost.group, description: cost.description}); // refresh to original state if cancelled
          } else {
            history.push('/room'); // if new cost creation, return to room
          }
        }}
      >
        Cancel
      </Button>
    )
  }
  
  const editContractButton = (params) => {
    return (
        <strong>
            <Button
                variant="contained" color="primary" size="small" style={{ marginLeft: 4 }} disabled={ id | !isUpdatingCost  ? false : true}
                onClick={() => {                  
                  setContractData({
                    cost_id: params.row.cost_id,
                    referenceD: params.row.referenceD,
                    contractTerm: params.row.contractTerm,
                    contractStart: params.row.contractStart.substr(0,10),
                    contractEnd: params.row.contractEnd.substr(0,10),
                    costPerRenewal: params.row.costPerRenewal,
                    seatCount: params.row.seatCount,
                    contractNotes: params.row.contractNotes,
                    autoRenew: params.row.autoRenew,
                    contractID: params.id
                  })
                    
                    setIsUpdatingContract(true);
                }
              }
            >
                Edit
            </Button>
        </strong>
    )
  }

  const deleteContractButton = (params) => {
    return (
      <Button
          variant="contained" color="secondary" size="small" style={{ marginLeft: 4 }} disabled={ id | !isUpdatingCost  ? false : true}
          onClick={() => {
            dispatch(deleteContract(params.id));
          }}
      >
          Delete
      </Button>
    )
  }

  const CancelContractButton = (params) => {

    return (
      <Button
        variant="contained" color="secondary" className={classes.submit} disabled={ id | !isUpdatingCost ? false : true}
        onClick={() => {
          setIsUpdatingContract(false);
          setIsRenewing({renewingStatus: false});
          clearContract();
        }}
      >
        Cancel
      </Button>
    )
  }

  const RenewButton = (params) => {
    const { id } = params;
    // const { contracts } = useSelector((state) => state.contracts);

    let adjust = 0;

    // determine what to show as button text and if it is active
    var renewButtonLabel
    var renewButtonDisabled = false

    if(renewedContracts.indexOf(params.id) !== -1){
      renewButtonLabel = 'Already Renewed';
      renewButtonDisabled = true
    } else {
      if (params.row.autoRenew === "Yes") {
          renewButtonLabel = 'Contract Autorenewing';
          renewButtonDisabled = true
      } else {
        renewButtonLabel = 'Renew Contract';
      }
    }

    return (
      <Button
          variant="contained" color="primary" size="small" style={{ marginLeft: 4 }}
          disabled={renewButtonDisabled}
          onClick={() => {
            switch (params.row.contractTerm) {
              case 'Annual': adjust = 12;
                break;
              case 'Semi-Annual': adjust = 6;
                break;
              case 'Quarterly': adjust = 3;
                break;
              case 'Monthly': adjust = 1;
                break;
              case 'Fixed-Duration': 
                break;
              default : 
              break;
            }
            
            let renewedStartDate = new Date(params.row.contractStart)
            let adjustedStart = addMonths(renewedStartDate, adjust, 1)
            let adjustedEnd = addMonths(new Date(adjustedStart.getTime()), adjust, 1)
            let xEnd = adjustedEnd.toISOString().substr(0, 10)
            setIsRenewing({renewingStatus: true, toAssignChildID: false, renewingID: id, parentRowData: params.row});

            setContractData({
              referenceD: '',
              cost_id: params.row.cost_id,
              contractStart: adjustedStart.toISOString().substr(0, 10),
              contractEnd: xEnd,
              contractTerm: params.row.contractTerm,
              costPerRenewal: params.row.costPerRenewal,
              seatCount: params.row.seatCount,
              autoRenew: params.row.autoRenew,
              contractNotes: params.row.contractNotes,
              contractID: params.id
            }) 
          }}
      >
        {renewButtonLabel}
      </Button>
    )
  }
  
  const columns = [
    { field: 'referenceD', headerName: 'Contract Reference / ID', width: 150 },
    { field: 'contractTerm', headerName: 'Contract Term', width: 150 },
    { field: 'contractStart', headerName: 'Contract Start', width: 150 },
    { field: 'contractEnd', headerName: 'Contract End', width: 150 },
    { field: 'costPerRenewal', headerName: 'Cost per Renewal', width: 150 },
    { field: 'seatCount', headerName: 'Seats / License', width: 150 },
    { field: 'autoRenew', headerName: 'Auto Renew?', width: 150 },
    { field: 'contractNotes', headerName: 'Contract Notes', width: 150 },
    { field: 'renewCol', headerName: '', width: 200, renderCell: RenewButton},
    { field: 'editCol', headerName: '', width: 100, renderCell: editContractButton}, 
    { field: 'deleteCol', headerName: '', width: 100, renderCell: deleteContractButton}, 
  ];
  
  const handleCostChange = (e) => setCostData({ ...costData, [e.target.name]: e.target.value });
  const handleContractChange = (e) => {
    let endAdjust = 0;
    let adjustedEndDate = new Date('2020/01/01');
    let formatStartDate = new Date('2020/01/01');
    if (contractData.contractStart) {
      formatStartDate = new Date(contractData.contractStart);
    } else {
      formatStartDate = today;
    }

    switch (e.target.name) {
      case 'contractTerm': // if the contract term has changed
        switch (e.target.value) {
          case 'Annual': endAdjust = 12;
            break;
          case 'Semi-Annual': endAdjust = 6;
            break;
          case 'Quarterly': endAdjust = 3;
            break;
          case 'Monthly': endAdjust = 1;
            break;
          case 'Fixed-Duration': endAdjust = 0;
            break;
          default : 
          break;
        }
        if (endAdjust !== 0) {  
            adjustedEndDate = addMonths(formatStartDate, endAdjust, -1).toISOString().substr(0, 10);
            setContractData({...contractData, [e.target.name]: e.target.value, contractEnd: adjustedEndDate});
            return;
        } else { // if fixed duration
          setContractData({ ...contractData, [e.target.name]: e.target.value });
        }
        break;
      case 'contractStart': // if the start date has changed
        switch (contractData.contractTerm) {
          case 'Annual': endAdjust = 12;
            break;
          case 'Semi-Annual': endAdjust = 6;
            break;
          case 'Quarterly': endAdjust = 3;
            break;
          case 'Monthly': endAdjust = 1;
            break;
          case 'Fixed-Duration': endAdjust = 0;
            break;
          default : 
          break;
        }
        formatStartDate = new Date(e.target.value)
        adjustedEndDate = addMonths(formatStartDate, endAdjust, -1).toISOString().substr(0, 10);
        setContractData({...contractData, [e.target.name]: e.target.value, contractEnd: adjustedEndDate});
        return;
      default:
      break;
    }
    
   setContractData({ ...contractData, [e.target.name]: e.target.value });
  }

  var createContractButton // Determining what to display on create button
  if (isUpdatingContract && !isRenewing.renewingStatus) {
    createContractButton = "Save Contract"
  } else if (isRenewing.renewingStatus===true) {
    createContractButton = "Save Renewed Contract"
  } else {
    createContractButton = "Create Contract"
  }

  return (
    <Container component="main" maxWidth="xl">
      <Paper className={classes.paper} elevation={6}>
        <Typography component="h1" variant="h5"> { cost && id ? '' : 'Create Cost' }</Typography>
        <form className={classes.form} onSubmit={handleCostSubmit} autoComplete="off">
          <Grid container spacing={2} component="h2">
            <Grid item xs={12} md={3}>
              <Input name="expenseName" label="Expense Name" value={costData.expenseName} handleChange={handleCostChange} disabled={ isUpdatingCost ? false : true} type="text" required={true}/>
            </Grid>
            <Grid item xs={12} md={3}>
              <Input name="vendor" label="Vendor" value={costData.vendor} handleChange={handleCostChange} disabled={ isUpdatingCost ? false : true} type="text" required={true}/>
            </Grid>
            <Grid item xs={12} md={3}>
              <Input name="group" label="Group" value={costData.group} handleChange={handleCostChange} disabled={ isUpdatingCost ? false : true} type="text"/>
            </Grid>
            <Grid item xs={12} md={3}>
              <Input name="description" label="Description" value={costData.description} handleChange={handleCostChange} disabled={ isUpdatingCost ? false : true} type="text" />
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" color="primary" className={classes.submit}  >
            {isUpdatingCost | !id ? "Save Cost" : "Edit Cost" }
          </Button>
          {isUpdatingCost ?
            <CancelEditCostButton />
            : ""}
        </form>
        <Typography component="h1" variant="h5"> { cost && id ? `${cost?.expenseName} contracts` : 'Save the vendor expense information to add a contract' }</Typography>
        <form className={classes.form} onSubmit={handleContractSubmit} >
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
                <Input name="referenceD" label="Contract Reference / ID" value={ contractData.referenceD } handleChange={handleContractChange} required variant="outlined" disabled={ id | !isUpdatingCost ? false : true}/>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <TextField select name="contractTerm" label="Contract Term" value={contractData.contractTerm} onChange={handleContractChange} required disabled={ id | !isUpdatingCost  ? false : true} variant="outlined">
                  <MenuItem value={'Annual'}>Annual</MenuItem>
                  <MenuItem value={'Fixed Duration'}>Fixed Duration</MenuItem>
                  <MenuItem value={'Monthly'}>Monthly</MenuItem>
                  {/*
                  <MenuItem value={'Semi-Annual'}>Semi-Annual</MenuItem>
                  <MenuItem value={'Quarterly'}>Quarterly</MenuItem>
                  */}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
                <Input name="contractStart" label="Contract Start" value={contractData.contractStart} handleChange={handleContractChange} type="date" shrinkTop required disabled={ id | !isUpdatingCost  ? false : true}/>
            </Grid>
            <Grid item xs={6} md={3}>
                <Input name="contractEnd" label="Contract End" value={contractData.contractEnd} handleChange={handleContractChange} type="date" shrinkTop required disabled={ (id | !isUpdatingCost) & contractData.contractTerm === "Fixed Duration"? false : true}/>
            </Grid>
            <Grid item xs={6} md={3}>
                <Input name="costPerRenewal" label="Cost Per Renewal" value={contractData.costPerRenewal} handleChange={handleContractChange} currency="$" type="currency" required disabled={ id | !isUpdatingCost  ? false : true} />
            </Grid>
            <Grid item xs={6} md={3}>
                <Input name="seatCount" label="Seat Count" value={contractData.seatCount} handleChange={handleContractChange} type="text" disabled={ id | !isUpdatingCost  ? false : true}/>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <TextField select name="autoRenew" label="Autorenew?" value={contractData.autoRenew} onChange={handleContractChange} required disabled={ id | !isUpdatingCost  ? false : true} variant="outlined">
                  <MenuItem value={'Yes'}>Yes</MenuItem>
                  <MenuItem value={'No, Manually Renew'}>No, Manually Renew</MenuItem>
                  <MenuItem value={'Final Contract'}>Final Contract</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <Input name="contractNotes" label="Additional Notes" value={contractData.contractNotes} handleChange={handleContractChange} type="text" disabled={ id | !isUpdatingCost  ? false : true}/>
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" color="primary" className={classes.submit} disabled={ id | !isUpdatingCost ? false : true}>
            {createContractButton}
          </Button>
          
          {isUpdatingContract || isRenewing.renewingStatus ?
            <CancelContractButton />
            : ""}
          <Grid container justify="flex-end">
            <Grid item>
              
            </Grid>
          </Grid>
        </form>

        <Grid container >
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid
            rows={ id ? contracts : []}
            columns={columns}
            getRowId={(row) => row._id}
            density="compact"
            />
          </div>
        </Grid>
       
      </Paper>
    </Container>
  );
};

export default Createcost;
