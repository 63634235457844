import React from 'react';
import { TextField, Grid, InputAdornment } from '@material-ui/core';


const Input = ({ name, handleChange, label, half, autoFocus, disabled, type, handleShowPassword, shrinkTop, value, defaultValue, currency, required }) => (
  <Grid item xs={12} sm={half ? 6 : 12}>
    <TextField
      name={name}
      onChange={handleChange}
      variant="outlined"
      required={required}
      fullWidth
      label={label}
      disabled={disabled}
      autoFocus={autoFocus}
      type={type}
      value={value}
      defaultValue={defaultValue}
      InputLabelProps={{ shrink: shrinkTop }}
      InputProps={currency === '$' ? {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
    } : null}
    
    />
  </Grid>
);

export default Input;

/* 
InputProps={name === 'password' ?endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={handleShowPassword}>
          {type === 'password' ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    ),
  }
  */