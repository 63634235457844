import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, CircularProgress, Container, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { getRenewals, getCosts } from '../../actions/costs'

import useStyles from './styles';

const RenewalTable = ({dashboard}) => {
  
  const dispatch = useDispatch();
  const history = useHistory();
  const renewals = useSelector((state) => state.costs.renewals);
  const { costs, isLoading } = useSelector((state) => state.costs);

  useEffect(() => {
    dispatch(getRenewals());
    dispatch(getCosts());

  }, [dispatch]);

  useEffect(() => {
    try {
      if (renewals.length !== 0) {
        renewals.forEach(element => {
          element.contractStart = element.contractStart.substring(0,10);
          element.contractEnd = element.contractEnd.substring(0,10);
        })
      }
    } catch(e) {}
      

  }, [renewals]);

  const renewContractButton = (params) => {
    return (
      <strong>
        <Button
          variant="contained" color="primary" size="small" style={{ marginLeft: 4 }}
          onClick={() => {
            
            openCost(params)
          }}
        >
          Renew Contract
        </Button>
      </strong>
    )
  }


  const columns = [
    { field: 'costName', headerName: 'Cost Name', width: 200 },
    { field: 'referenceD', headerName: 'Contract Reference', width: 200 },
    { field: 'contractTerm', headerName: 'Contract Term', width: 150 },
    { field: 'contractStart', headerName: 'Contract Start', width: 125 },
    { field: 'contractEnd', headerName: 'Contract End', width: 125 },
    { field: 'daysToRenewal', headerName: 'Days to Renewal', width: 150 },
    { field: 'costPerRenewal', headerName: 'Prior Renewal $', width: 150 },
  ];

  if (dashboard) {
  } else {
    columns.push({ field: 'renewCol', headerName: 'Renew', width: 200, renderCell: renewContractButton })
  }

  const openCost = (params) => {
    // dispatch(getPost(post._id, history));

    history.push(`/room/${params.row.cost_id}`);
  };

  return (
    isLoading ? <CircularProgress /> : (
      <Container component="main" maxWidth="xl">
        <Paper>
          <Grid container >
            <div style={{ height: 500, width: '100%' }}>
              
              <DataGrid
                rows={renewals ? renewals : []}
                columns={columns}
                getRowId={(row) => row._id}
                density="compact"
              />
            
            </div>
          </Grid>
        </Paper>
      </Container>
    )
  );
};

export default RenewalTable;
