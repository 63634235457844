import { FETCH_ALL_COSTS, FETCH_BY_SEARCH, FETCH_COST, CREATE, UPDATE, DELETE_COST, FETCH_RENEWALS} from '../constants/actionTypes';

export default (state = { isLoading: true, costs: [], renewals: []}, action) => {
  switch (action.type) {
    case 'START_LOADING_COST':
      return { ...state, isLoading: true };
    case 'END_LOADING':
      return { ...state, isLoading: false };
    case FETCH_ALL_COSTS:
      return {
        ...state,
        costs: action.payload.data,
      };
    case FETCH_BY_SEARCH:
      return { ...state, costs: action.payload.data };
    case FETCH_COST:
      return { ...state,
        cost: action.payload.cost,
      };
    case FETCH_RENEWALS:
      
      return { ...state,
        renewals: action.payload.data,
      };
    case CREATE:
      return { ...state, costs: [...state.costs, action.payload] };
    case UPDATE:
      return { ...state, costs: state.costs.map((cost) => (cost._id === action.payload._id ? action.payload : cost)) };
    case DELETE_COST:
      return { ...state, costs: state.costs.filter((cost) => cost._id !== action.payload) };
    default:
      return state;
  }
};

