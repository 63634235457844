export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const DELETE_ALL = 'DELETE_ALL';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_POST = 'FETCH_POST';
export const FETCH_COST = 'FETCH_COST';
export const LIKE = 'LIKE';
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';

export const FETCH_CONTRACT = 'FETCH_CONTRACT';
export const FETCH_ALL_CONTRACTS = 'FETCH_ALL_CONTRACTS';
export const START_LOADING_CONTRACT = 'START_LOADING_CONTRACT';
export const GET_CHART_DATA = 'GET_CHART_DATA';

export const FETCH_ALL_COSTS = 'FETCH_ALL_COSTS';
export const DELETE_COST = 'DELETE_COST';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

export const NEW_USER = 'NEW_USER';
export const NEW_GOOGLE_USER = 'NEW_GOOGLE_USER';

export const FETCH_RENEWALS = 'FETCH_RENEWALS';
