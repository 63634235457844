import React from 'react';
import { Grid } from '@material-ui/core';
import { Doughnut } from "react-chartjs-2";


const DashBarChart = ({ chartData, chartTitle }) => {

  const testdata = {
    labels: ['Red', 'Orange', 'Blue'],
    datasets: [
      {
        label: 'Popularity of colours',
        data: [3, 2, 1],
        // you can set indiviual colors for each bar
        backgroundColor: [
          '001219',
          'blue',
          'green',
        ],
        borderWidth: 1,
        
      }
    ]
  }
  let chartDataset
  if (typeof chartData?.labels !== "undefined") {
    chartDataset = {
      labels: chartData.labels,
      datasets: [
      {
        label: chartData.datasets[0].label,
        data: chartData.datasets[0].data,
        // you can set indiviual colors for each bar
        backgroundColor: [
          'rgb(0, 18, 25)',
          'rgb(0, 95, 115)',
          'rgb(10, 147, 150)',
          'rgb(148, 210, 189)',
          'rgb(233, 216, 166)',
          'rgb(238, 155, 0)',
          'rgb(202, 103, 2)',
          'rgb(187, 62, 3)',
          'rgb(174, 32, 18)',
          'rgb(155, 34, 38)',
        ],
        borderWidth: 1,
        
      }
    ]
    }

  }



  return (
    <Grid >
      <div>
        <Doughnut
          data={typeof chartData?.labels !== "undefined" ? chartDataset : testdata}
          options={{
            plugins: {
              title: {
                display: true,
                text: chartTitle,
                padding: {
                  top: 20,
                  bottom: 30
                },
                font: {
                  size: 20
                }
              },
              legend: {
                display: true,
                position: "top"
              },
            }
          }}
        />
      </div>
    </ Grid>

  );
};

export default DashBarChart;
