import React from 'react';
import { Grid } from '@material-ui/core';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );



const DashLineChart = ({ chartData, chartTitle, fill, label }) => {  
  const d = new Date(); // get month to know where to skip projection
  let month = d.getMonth();

  

  // const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
  const skipped = (ctx, value) => {
    return ctx.p1.parsed.x > month-1 ? value : undefined;

  }
  
  // const down = (ctx, value) => ctx.p0.parsed.y < ctx.p1.parsed.y ? value : undefined;
  
  var chartArray
  var ytdData
  try  {
    if (chartData.datasets[0].data) {
      chartArray = chartData.datasets[0].data
    } else{
      chartArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]  
    }
  } catch(e) {
    chartArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  }
  
  
  try {
    ytdData = chartArray.slice(0,month)
    
  } catch(e) {
  }
  
  
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
          title: {
            display: true,
            text: chartTitle,
            padding: {
                  top: 20,
                  bottom: 30
                },
                font: {
                  size: 20
                }
          },
        },
      };

      const labels = chartData.labels;
      const data = {
        labels,
        datasets: [
          {
            label: "Projected Spend",
            data: chartArray,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            fill: false,
            segment: {
              borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)'),
              borderDash: ctx => skipped(ctx, [6, 6]),
              fill: ctx => skipped(ctx, false),
            },
            spanGaps: true

          },
          {
            data: ytdData,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            fill: true,
          },
        ],
      };

      return (
        <Grid >
          <div>
          <Line options={options} data={data} />
            </div>
        </Grid>
      );
}

export default DashLineChart;
