import React from 'react';
import { Card, CardActions, CardContent, Typography, ButtonBase } from '@material-ui/core/';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';

const DashTile = ({head, content, foot, type}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));
  const history = useHistory();

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


  return (
    <Card className={classes.card} raised elevation={6}>
      <ButtonBase
        component="span"
        name="test"
        className={classes.cardAction}
        //onClick={console.log('onClick called')}
      >
        <div className={classes.details}>
          <Typography variant="body1" color="textSecondary" component="h2">{head? head : 'header'}</Typography>
        </div>
        <Typography className={classes.title} gutterBottom variant="h4" component="h2">{content ? formatter.format(content) : '0'}</Typography>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">{foot ? foot : 'foot'}</Typography>
        </CardContent>
      </ButtonBase>
      <CardActions className={classes.cardActions}>

      </CardActions>
    </Card>
  );
};

export default DashTile;
