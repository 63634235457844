/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { Pagination, PaginationItem } from '@material-ui/lab';
// import { Link } from 'react-router-dom';

import { getPosts } from '../actions/posts';
import useStyles from './styles';

const Paginate = ({ page }) => {
  // const { numberOfPages } = useSelector((state) => state.posts);
  const dispatch = useDispatch();



  useEffect(() => {
    if (page) {
      dispatch(getPosts(page));
    }
  }, [dispatch, page]);

  return (
    <br/>
    
  );
};

export default Paginate;
