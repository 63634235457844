import React, { useState, useEffect } from 'react';
import { AppBar, Typography, Toolbar, Avatar, Button } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';

import ExpenseRoomLogo from '../../images/ExpenseRoomLogo.JPG';
import ExpenseRoomText from '../../images/ExpenseRoomText.JPG';
import * as actionType from '../../constants/actionTypes';
import useStyles from './styles';

const Navbar = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  
  const logout = () => {
    dispatch({ type: actionType.LOGOUT });

    history.push('/start');

    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 10000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location, user?.token]);

  return (
    <AppBar className={classes.appBar} position="static" color="inherit">
      <Link to={user?.result ? "/room" : "/start"} className={classes.brandContainer}>
        <img component={Link} to="/" src={ExpenseRoomText} alt="icon" height="45px" />
        <img className={classes.image} src={ExpenseRoomLogo} alt="icon" height="40px" />
      </Link>
      {user?.result ? (
        <div className={classes.profile}>
          <Button className={classes.searchButton} component={Link} to="/room" variant="contained" color="primary"> Home </Button>
          <Button className={classes.searchButton} component={Link} to="/createcost" variant="contained" color="primary"> Create a cost </Button>
          <Button className={classes.searchButton} component={Link} to="/dashboard" variant="contained" color="primary"> Dashboard </Button>
        </div>
      ): <div></div>}
      <Toolbar className={classes.toolbar}>
        {user?.result ? (
          <div className={classes.profile}>
            <Avatar className={classes.purple} alt={user?.result.name} src={user?.result.imageUrl}>{user?.result.name.charAt(0)}</Avatar>
            <Typography className={classes.userName} variant="h6">{user?.result.name}</Typography>
            <Button variant="contained" className={classes.logout} color="secondary" onClick={logout}>Logout</Button>
          </div>
        ) : (
          <Button component={Link} to="/auth" variant="contained" color="primary">Sign In</Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
