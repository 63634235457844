import { START_LOADING, END_LOADING, GET_CHART_DATA } from '../constants/actionTypes';

export default (state = { isLoading: true, chartData: [], monthlyExpenseData: [], renewalByGroupData: [], dashTileData:[], monthlyCumulExpenseData:[]}, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case GET_CHART_DATA:
      
      return {
        ...state,
        chartData: action.payload.data.chartData,
        monthlyExpenseData: action.payload.data.monthlyExpenseData,
        renewalByGroupData: action.payload.data.renewalByGroupData,
        dashTileData: action.payload.data.dashTileData,
        monthlyCumulExpenseData: action.payload.data.monthlyCumulExpenseData
      };
    default:
      return state;
  }
};

