import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grow, Grid, Paper, Typography } from '@material-ui/core';
import DashBarChart from '../DashBarChart/DashBarChart';
import DashDonutChart from '../DashDonutChart/DashDonutChart'
import DashLineChart from '../DashLineChart/DashLineChart'
import { getCosts } from '../../actions/costs'
import { getDashChartData } from '../../actions/dashboard';
import DashTile from './DashTile/DashTile';
import RenewalTable from '../RenewalTable/RenewalTable'
import Pagination from '../Pagination';
import { useLocation } from 'react-router-dom';
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'

import useStyles from './styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const Dashboard = () => {
  const classes = useStyles();
  const query = useQuery();
  const page = query.get('page') || 1;
  const dispatch = useDispatch();

  const [chartData, setChartData] = useState({}) // Renewals by Expense in 2022
  const [monthlyRenewals, setMonthlyRenewals] = useState({}) // Renewals by Month in 2022
  const [monthlyCumulRenewals, setMonthlyCumulRenewals] = useState({}) // Renewals by Month in 2022
  const [renewalByGroup, setRenewalByGroup] = useState({}) // Renewals by Group in 2022

  useEffect(() => {
    dispatch(getDashChartData());
    dispatch(getCosts());
  }, [dispatch]);

  const chartDataFromState = useSelector((state) => state.chartData.chartData);
  const monthlyExpenseData = useSelector((state) => state.chartData.monthlyExpenseData);
  const monthlyCumulExpenseData = useSelector((state) => state.chartData.monthlyCumulExpenseData);
  const renewalByGroupData = useSelector((state) => state.chartData.renewalByGroupData);
  const dashTileData = useSelector((state) => state.chartData.dashTileData);
  


  useEffect(() => {
    const createChart = async () => {
      setChartData({
        labels: chartDataFromState.labels,
        datasets: [
          {
            label: "Annual Expense [$USD]",
            data: chartDataFromState.data,
            backgroundColor: '#ffbb11'
          }
        ]
      });

      setMonthlyRenewals({
        labels: monthlyExpenseData.labels,
        datasets: [
          {
            label: "Total Renewal Amount",
            data: monthlyExpenseData.data,
            backgroundColor: '#ffbb11'
          }
        ]
      });
      
      setMonthlyCumulRenewals({
        labels: monthlyCumulExpenseData.labels,
        datasets: [
          {
            label: "Total Renewal Amount",
            data: monthlyCumulExpenseData.data,
            backgroundColor: '#ffbb11'
          }
        ]
      });

      setRenewalByGroup({
        labels: renewalByGroupData.labels,
        datasets: [
          {
            label: "Total Renewal Amount",
            data: renewalByGroupData.data,
          }
        ]
      });

    };

    createChart()
    

  }, [dispatch, chartDataFromState, monthlyExpenseData, monthlyCumulExpenseData, renewalByGroupData]);
  
  return (
    <Grow in>
      <Container maxWidth="xl">
        <Grid container justify="space-between" alignItems="stretch" spacing={3} className={classes.gridContainer}>
        <Grid item xs={6} sm={3} md={3}>
          <DashTile head='Annualized Expense' content={dashTileData.annualizedExpense} foot='USD per year' type='currency'/>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <DashTile head='Average Monthly Expense' content={dashTileData.annualizedExpense/12} foot='USD per month'/>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <DashTile head='Year to Date Renewals' content={dashTileData.ytdRenewals} foot='USD total'/>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <DashTile head='Renewals Remaining This Year' content={dashTileData.renewalsRemaining} foot='USD total'/>
        </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Paper elevation={6}>
              < DashBarChart chartData={chartData} colorBars={true} chartTitle="Renewals By Expense in 2022"/>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Paper elevation={6}>
              < DashBarChart chartData={monthlyRenewals} colorBars={false} chartTitle="Renewals By Month in 2022"/>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <Paper elevation={6}>
              < DashLineChart fill={false} label='Cumulative Spend' chartData={monthlyCumulRenewals} chartTitle="Yearly Renewal Progress"/>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Paper elevation={6}>
              < DashDonutChart chartData={renewalByGroup} chartTitle="Annual Renewals by Group"/>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Paper>
              <Typography className={classes.title} gutterBottom variant="h4" component="h4" align="center">
              Contracts Renewing Soon
            </Typography>
            <RenewalTable dashboard={true}/>
            </Paper>
          </Grid>
          {(<Pagination page={page} />)}
        </Grid>
      </Container>
    </Grow>
  );
};

export default Dashboard;
