import { START_LOADING, FETCH_ALL_COSTS, FETCH_COST, CREATE, UPDATE, DELETE_COST, FETCH_RENEWALS } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const createCost = (cost, history) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.createCost(cost);
      

      dispatch({ type: CREATE, payload: data });
  
      history.push(`/room/`); 
      history.push(`/room/${data._id}`); // this pushes the browser to the created ID
    } catch (error) {
      console.log(error);
    }
};

export const getCost = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchCost(id);

    dispatch({ type: FETCH_COST, payload: { cost: data } });
  } catch (error) {
    console.log(error);
  }
};

export const getCosts = () => async (dispatch) => {
    try {
      const { data } = await api.fetchCosts();
      dispatch({ type: FETCH_ALL_COSTS, payload: data });
    } catch (error) {
      console.log(error);
    }
};

export const deleteCost = (id) => async (dispatch) => {
  try {
    await await api.deleteCost(id);

    dispatch({ type: DELETE_COST, payload: id });
  } catch (error) {
    console.log(error);
  }
};

export const updateCost = (id, cost) => async (dispatch) => {
  try {
    const { data } = await api.updateCost(id, cost);

    dispatch({ type: UPDATE, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getRenewals = () => async (dispatch) => {
  try {
    const { data } = await api.getRenewals();
    dispatch({ type: FETCH_RENEWALS, payload: data });
  } catch (error) {
    console.log(error);
  }
};