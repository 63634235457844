import React from 'react';
import { Paper, Typography, Container, Grow, Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import Costs from '../Costs/Costs';
import RenewalTable from '../RenewalTable/RenewalTable'
import Pagination from '../Pagination';
import useStyles from './styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Room = () => {
  const classes = useStyles();
  const query = useQuery();
  const page = query.get('page') || 1;
  // const searchQuery = query.get('searchQuery');


  
  // const [tags] = useState([]);
  

  return (
    <Grow in>
      <Container maxWidth="xl">
        <Grid container justify="space-between" alignItems="stretch" spacing={3} className={classes.gridContainer}>        
          <Grid item xs={12} sm={12} md={12}>
          <Paper>
            <Typography className={classes.title} gutterBottom variant="h4" component="h4" align="center">
              All Active Subscriptions
            </Typography>
            <Costs />
          </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Paper>
              <Typography className={classes.title} gutterBottom variant="h4" component="h4" align="center">
                Contracts Renewing Soon
              </Typography>
            <RenewalTable />
            </Paper>
          </Grid>
        {(<Pagination page={page} />)}
        </Grid>
      </Container>
    </Grow>
  );
};

export default Room;
