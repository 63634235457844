import React from 'react';
import { Grid } from '@material-ui/core';
import { Bar } from "react-chartjs-2";

const DashBarChart = ({ chartData, colorBars, chartTitle }) => {

  const testdata = {
    labels: ['Red', 'Orange', 'Blue'],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Popularity of colours',
        data: [3, 2, 1],
        // you can set indiviual colors for each bar
        backgroundColor: [
          'rgb(0, 18, 25)',
          'rgb(0, 95, 115)',
          'rgb(10, 147, 150)',
          'rgb(148, 210, 189)',
          'rgb(233, 216, 166)',
          'rgb(238, 155, 0)',
          'rgb(202, 103, 2)',
          'rgb(187, 62, 3)',
          'rgb(174, 32, 18)',
          'rgb(155, 34, 38)',
        ],
        borderWidth: 1,
      }
    ]
  }

  const isEmpty = Object.keys(chartData).length === 0;
  
  if (!isEmpty && colorBars) {
    chartData.datasets[0].backgroundColor = [
      'rgb(0, 18, 25)',
      'rgb(0, 95, 115)',
      'rgb(10, 147, 150)',
      'rgb(148, 210, 189)',
      'rgb(233, 216, 166)',
      'rgb(238, 155, 0)',
      'rgb(202, 103, 2)',
      'rgb(187, 62, 3)',
      'rgb(174, 32, 18)',
      'rgb(155, 34, 38)',
    ]
  }

  return (
    <Grid >
      <div>
        <Bar
          data={!isEmpty ? chartData : testdata}
          options={{
            plugins: {
              title: {
                display: true,
                text: chartTitle,
                padding: {
                  top: 20,
                  bottom: 30
                },
                font: {
                  size: 20
                }
              },
              legend: {
                display: false,
                position: "bottom"
              }
            }
          }}
        />
      </div>
    </ Grid>

  );
};

export default DashBarChart;
