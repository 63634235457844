import { FETCH_ALL_CONTRACTS, FETCH_BY_SEARCH, FETCH_CONTRACT, CREATE, UPDATE, DELETE, DELETE_ALL} from '../constants/actionTypes';

export default (state = { isLoading: true, contracts: [] }, action) => {
  switch (action.type) {
    case 'START_LOADING_CONTRACT':
      return { ...state, isLoading: true };
    case 'END_LOADING':
      return { ...state, isLoading: false };
    case FETCH_ALL_CONTRACTS:
      return {
        ...state,
        contracts: action.payload.data,
      };
    case FETCH_BY_SEARCH:
      return { ...state, contracts: action.payload.data };
    case FETCH_CONTRACT:
      return { ...state, contract: action.payload.contract };
    case CREATE:
      return { ...state, contracts: [...state.contracts, action.payload] };
    case UPDATE:
      return { ...state, contracts: state.contracts.map((contract) => (contract._id === action.payload._id ? action.payload : contract)) };
    case DELETE:
      return { ...state, contracts: state.contracts.filter((contract) => contract._id !== action.payload) };
    case DELETE_ALL:
      return { ...state, contracts: state.contracts.filter((contract) => contract._id !== action.payload) };
      default:
      return state;
  }
};

