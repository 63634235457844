import { START_LOADING, GET_CHART_DATA, FETCH_COST} from '../constants/actionTypes';
import * as api from '../api/index.js';

export const getCost = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchCost(id);

    dispatch({ type: FETCH_COST, payload: { cost: data } });
  } catch (error) {
    console.log(error);
  }
};

export const getDashChartData = () => async (dispatch) => {
    try {
      const { data } = await api.getDashChartData();
      dispatch({ type: GET_CHART_DATA, payload: data });
    } catch (error) {
      console.log(error);
    }
};

