import { START_LOADING, START_LOADING_CONTRACT, FETCH_ALL_CONTRACTS, FETCH_CONTRACT, CREATE, UPDATE, DELETE, DELETE_ALL } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const createContract = (contract, history) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.createContract(contract);
      
      
      dispatch({ type: CREATE, payload: data });

    } catch (error) {
      console.log(error);
    }
};

export const getContract = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_CONTRACT });
    const { data } = await api.fetchContract(id);

    dispatch({ type: FETCH_CONTRACT, payload: { contract: data } });
  } catch (error) {
    console.log(error);
  }
};

export const getContracts = (id) => async (dispatch) => { // id is cost id
  try {
    dispatch({ type: START_LOADING_CONTRACT });
    const { data } = await api.fetchContracts(id);

    dispatch({ type: FETCH_ALL_CONTRACTS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const deleteContract = (id) => async (dispatch) => {
  try {
    await await api.deleteContract(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.log(error);
  }
};

export const updateContract = (id, contract) => async (dispatch) => {
  try {
    const { data } = await api.updateContract(id, contract);

    dispatch({ type: UPDATE, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const deleteContracts = (id) => async (dispatch) => { 
  try {
    await await api.deleteContracts(id);

    dispatch({ type: DELETE_ALL, payload: id });
  } catch (error) {
    console.log(error);
  }
};